import type { GetServerSideProps } from 'next';
import React from 'react';
import styles from '../styles/Home.module.css';

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = () => {
  return (
    <>
      <link rel="icon" type="image/png" sizes="16x16" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAmtJREFUOE99k11IU2EYx//POcezSiuxlhFGtsqpU5OZ4I1EFwoW1FWYGUUWImVIURoi0S76AEXyaxRWV10EtYgQ+lLYVYgf08oGhSRiDK0MaTptnnPeeN/c2lbsuTg8n7/3eZ7zvoQYGVR35CiGckoHK5FAqTzMQNMS4TUj7a49OO6NLKGQ8QHZalA2WhmxGgByLHjF1iUYXZq27uIeDC9znwCIYkXrYaCSUOFqezYSC/PANA3+3jcITvoimS9NmnzQBm9QAEbkjDuMqFpkECGtrQnmmqNC52IEFvHlwnXM3nsUhjAGZ4H+8SwNqZm5ksFGAUg8aq47jrSWxn8mYJqOT3uPIDDwPhTTIel55JEzOkBUG/LaJtxQ0zZDm53DZFU9TLvSw8C5x88xUXH+bxdAG3kUK99qluheUZC/OCYSfjx4ismTl4X+s/8FBpZMWPbNiDGO9bmEn4HGOMAPIIk7pFUm7Pa/FcHv3Q8xfeM2GqqvYEhORu6mNThXlIrqZxMiziGVfS4/eWTrPAiJsYDAiBdd8+vR0j+DxuItqLKbsVaVkdrsCY9w8/61QNQIkR24Bz+j3D0nkvtOZGF7sgmJqhQFyJwaX6CRhMxWxpjYTCTA0dEL51JK+LTO/ek4bEuJAmz95puiUXWnzTBkPrhMagLyF/78Jkf7Kzh/bYwLEIvnn2HFeouAOq5bXJ1I2leEJ2eaUZt3KC6AgdwCMISCBFLmewgojbxB9aeb8M6SLVztZdtQnrMhagQwOOI+Jl7MIf8VBsfXhoKrYUAoKfScGagUYBbu7y6r9LuKD5hXLo9bJt0xfanQze3fKlDsh0ziFmgAAAAASUVORK5CYII=" />
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
        </div>
      </main>
    </>
  );
};

export default Home;
